<template>
    <div>
        <v-app-bar
            :color="getCompany.color_appbar || ($vuetify.theme.dark ? 'dark' : getThemeMode.appBarColor)"
            :dark="getThemeMode.appBarColor !== 'white'"
            app
            class="text-left shadow-sm no-print"
            flat
            height="75"
        >
            <v-app-bar-nav-icon
                v-if="sideBarDrawer"
                v-ripple="{ class: 'primary--text' }"
                @click="toggleVerticalSidebarDrawer"
            />
            <v-progress-linear
                :active="getThemeMode.isLoading"
                :indeterminate="getThemeMode.isLoading"
                absolute
                bottom
                color="primary"
            />

            <c-icon
                class="app-bar-icon-color"
                v-if="showBackButton"
                icon
                mdi-icon="mdi-less-than"
                @click="$router.go(-1)"
            />

            <div
                v-ripple
                class="ml-4 mt-1"
                style="cursor: pointer"
                @click="goHome"
            >
                <img
                    :src="getImagePath(getLogo)"
                    alt=""
                    style="max-height: 60px"
                />
            </div>

            <v-spacer />
            <v-spacer />

            <!-- <c-btn
                class="app-bar-icon-color ml-2"
                icon
                x-large
                mdi-icon="mdi-poll"
                to="/app/pages/ranking"
            ></c-btn> -->

            <!--          Admin section icon-->
            <c-btn
                v-if="showAdminConfig"
                class="app-bar-icon-color ml-2"
                icon
                x-large
                mdi-icon="mdi-account-wrench"
                to="/app/admin"
            />

            <c-btn
                class="app-bar-icon-color ml-2 mr-1"
                icon
                x-large
                mdi-icon="mdi-account-details"
                @click="userDrawer = !userDrawer"
            />
        </v-app-bar>
        <!-- userDrawer -->
        <v-navigation-drawer
            v-model="userDrawer"
            fixed
            right
            height="100%"
            temporary
            floating
            width="350"
        >
            <user-drawer
                :is-visible="userDrawer"
                @close="userDrawer = false"
            >
                <template v-slot:userDrawerCloseButton>
                    <v-btn
                        icon
                        @click.stop="userDrawer = !userDrawer"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </user-drawer>

            <template v-slot:append>
                <div class="my-4 mx-4">
                    <base-hover-button
                        :text="$t('users.login.logout')"
                        block
                        bg-color="primary lighten-5 primary--text"
                        icon-name="mdi-logout"
                        @click.native="logoutUser"
                    />
                </div>
            </template>
        </v-navigation-drawer>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'VerticallAppBar',
    components: {
        UserDrawer: () => import('../common-drawer/UserDrawer.vue'),
        CBtn: () => import('../../components/base/c-btn.vue'),
        CIcon: () => import('../../components/base/c-icon.vue')
        /* NotificationDrawer: () =>
            import('../common-drawer/NotificationDrawer.vue'),
        SearchDrawer: () => import('../common-drawer/SearchDrawer.vue') */
    },
    computed: {
        ...mapGetters([
            'getThemeMode',
            'getUserName',
            'getUserAcronym',
            'isAdmin',
            'getUserPicture',
            'getLogo',
            'getCompany'
        ]),
        // ...mapGetters('statistics', ['getTotalPoints']),
        getUserNameBar() {
            return this.getUserName && this.getUserName.length < 8
                ? this.getUserName
                : this.getUserName.substring(0, 6) + '...';
        },
        showAdminConfig() {
            if (this.$route.path.startsWith('/app/admin')) {
                return false;
            }

            return this.isAdmin;
        },
        showBackButton() {
            return (
                this.$route.path !== '/app/pages/Home' &&
                !this.$route.path.startsWith('/app/admin') &&
                !this.$route.path?.includes('/app/pages/evaluate/users/')
            );
        }
    },
    props: {
        sideBarDrawer: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            userDrawer: false,
            notificationDrawer: false,
            searchDrawer: false

            /* navbarOptions: {
               elementId: 'main-navbar',
               isUsingVueRouter: true,
               mobileBreakpoint: 992,
               brandImagePath: './',
               brandImageAltText: 'brand-image',
               collapseButtonOpenColor: '#661c23',
               collapseButtonCloseColor: '#661c23',
               showBrandImageInMobilePopup: true,
               ariaLabelMainNav: 'Main Navigation',
               tooltipAnimationType: 'shift-away'
                menuOptionsLeft: [
                   {
                       type: 'link',
                       text: 'Dashboard',
                       iconLeft: '<i class="mdi mdi-view-dashboard"></i>',
                       subMenuOptions: [
                           {
                               type: 'link',
                               text: 'Learning Management',
                               path: { name: 'learning-management' },
                               iconLeft:
                                   '<i class="mdi mdi-circle-medium"></i>'
                           },

                           {
                               type: 'link',
                               text: 'Job Management',
                               path: { name: 'job-management' },
                               iconLeft:
                                   '<i class="mdi mdi-circle-medium"></i>'
                           },
                           {
                               type: 'link',
                               text: 'Analytic',
                               path: { name: 'analytic' },
                               iconLeft:
                                   '<i class="mdi mdi-circle-medium"></i>'
                           },
                           {
                               type: 'link',
                               text: 'Cryptocurrency',
                               path: { name: 'crypto-currency' },
                               iconLeft:
                                   '<i class="mdi mdi-circle-medium"></i>'
                           },
                           {
                               type: 'link',
                               text: 'Sales',
                               path: { name: 'sales' },
                               iconLeft:
                                   '<i class="mdi mdi-circle-medium"></i>'
                           },
                           {
                               type: 'link',
                               text: 'Subscription',
                               path: { name: 'donation' },
                               iconLeft:
                                   '<i class="mdi mdi-circle-medium"></i>'
                           }
                       ]
                   }
               ]
           } */
        };
    },
    methods: {
        ...mapActions(['changeVerticalSidebarDrawer', 'changeVerticalSidebarMini', 'signOut']),
        toggleVerticalSidebarDrawer() {
            this.changeVerticalSidebarDrawer();

            // this.$emit("update:mini-variant");
            // console.log("check");
        },
        logoutUser() {
            this.signOut();

            this.$router.push('/app/sessions/sign-in');
        },
        goHome() {
            if (this.$route.path !== '/app/pages/Home') {
                this.$router.push('/app/pages/Home');
            }
        },
        getImagePath(image) {
            return image
                ? process.env.VUE_APP_API_ENDPOINT + 'media/imagesSimple?filename=companies/images/' + image
                : require('@/assets/img/logo-default.png');
        }
    }
};
</script>

<style lang="scss">
.tippy-box[data-theme~='light'] {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.vnb {
    background: transparent !important;
    &__menu-options {
        margin-top: 3px !important;
        &__option {
            &__link {
                &:focus {
                    outline: none;
                    border: 1px solid none;
                }
                &:hover {
                    color: #0081ff;
                    .vnb__menu-options__option__arrow {
                        fill: #0081ff;
                    }
                }

                &__icon {
                    svg {
                        fill: #0081ff !important;
                    }
                }
            }
        }
    }

    &__sub-menu-options {
        &__option {
            &__link {
                &:focus {
                    outline: none;
                    border: 1px solid none;
                }
                color: #000 !important;
                &:hover {
                    color: #0081ff !important;
                }
            }
        }
    }
}

.vnb__collapse-button {
    &:focus {
        border: 1px solid none;
        outline: none;
    }
    &:after {
        content: '\F035C';
        font-size: 25px;
        font-weight: 600;
        font-family: 'Material Design Icons';
    }
    svg {
        display: none !important;
    }
}

.vnb__popup {
    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: scroll !important;
    .vnb__popup__top__close-button {
        &:focus {
            border: 1px solid none;
            outline: none;
        }
        top: 20px;
        right: -33px;
        svg {
            fill: #000 !important;
        }
    }
}
</style>
